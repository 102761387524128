<template>
  <v-container fluid>
    <div class="text-h5">ステータス</div>
    <v-divider class="ma-0"></v-divider>
    <v-tabs fixed-tabs>
      <v-tab>一覧・編集</v-tab>
      <v-tab>一括登録</v-tab>
      <v-tab-item>
        <v-card>
          <StatusList></StatusList>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>

import StatusList from "../../molecules/StatusList";
import firebase from "../../../plugins/firebase";

export default {
  name: "Status",
  components: {
    StatusList,
  },
  created() {
    firebase.onAuth();
  }
}
</script>

<style scoped>

</style>